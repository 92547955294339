import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { css, up, styled, thd } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import { withSettings } from 'containers/WebApp/SettingsContext';
import { trailingSlashIt } from '_platform/src/utils/utilities';
import Logo from './images/Footer-Logo@2x.png';

const FooterWrapper = styled.footer`
  background-color: #1c355e;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0.94rem 0;
  justify-content: center;
  align-items: center;
  p {
    color: ${thd('primary', '#003E7E')};
    font-size: 16px;
  }
  .footer__logo {
    vertical-align: middle;
    height: 90px;
  }
  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.8rem 0 0;
    ${up(
      'md',
      css`
        flex-direction: row;
      `
    )};
  }
  li {
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    ${up(
      'md',
      css`
        border-right: 1px solid #fff;
        padding: inherit;
        text-align: left;
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: #fff;
      font-weight: bold;
      text-decoration: none;
      padding: 0.1rem 1rem;
      font-size: 14px;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
`;

const LogoContainers = styled.div`
  height: 100px;
`;

const Footer = ({ siteName, location }) => (
  <FooterWrapper>
    <FooterContainer>
      <LogoContainers>
        <img src={Logo} className="footer__logo" alt="Patties Food Group " />
      </LogoContainers>
      {trailingSlashIt(location.pathname) !== '/login/' && (
        <div>
          <ul>
            <li>
              <Link to="/terms">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <PrivateComponent>
              <li>
                <Link to="/faqs">FAQs</Link>
              </li>
            </PrivateComponent>
            <li>
              <Link to="/sitemap">Sitemap</Link>
            </li>
          </ul>
        </div>
      )}
    </FooterContainer>
  </FooterWrapper>
);

Footer.propTypes = {
  siteName: PropTypes.string,
  location: PropTypes.object.isRequired,
};

Footer.defaultProps = {
  siteName: 'Lori Program Default',
};

export default withSettings(Footer);
